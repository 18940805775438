import { ref, Ref } from 'vue'

const qrData: Ref<string | null> = ref(null)

const useQrStorage = () => {
  const save = (data: string) => {
    localStorage.setItem('qrcode-data', data)
    qrData.value = data
  }
  const refresh = () => {
    qrData.value = localStorage.getItem('qrcode-data')
    return qrData
  }
  const clear = () => {
    localStorage.removeItem('qrcode-data')
    qrData.value = null
  }
  return {
    refresh,
    save,
    clear,
    qrData
  }
}

export default useQrStorage
