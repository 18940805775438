<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{ $t('home.title') }}</ion-title>
        <ion-buttons v-if="qrcodeData" slot="end">
          <ion-button @click="openPopover">
            <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <h1>{{ $t('home.header') }}</h1>
        <div v-if="!qrcodeData">
          <p>{{ $t('home.msg1') }}</p>
          <p>{{ $t('home.msg2') }}</p>
          <ion-button :router-link="{ name: 'Configure' }">{{
            $t('home.configure')
          }}</ion-button>
        </div>
        <div v-else>
          <img :src="qrImageData" class="qr-image" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  popoverController
} from '@ionic/vue'
import { defineComponent } from 'vue'
import MenuPopover from '@/components/MenuPopover'
import useQrStorage from '@/composables/storage-service'
import { Encoder } from '@nuintun/qrcode'

export default defineComponent({
  name: 'Home',
  components: {
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar
  },
  setup() {
    const qrStorage = useQrStorage()
    const qrcodeData = qrStorage.refresh()
    return {
      qrcodeData
    }
  },
  computed: {
    qrImageData() {
      const qrcode = new Encoder()
      qrcode.write(this.qrcodeData)
      qrcode.make()
      return qrcode.toDataURL(12)
    }
  },
  methods: {
    async openPopover(event) {
      const popover = await popoverController.create({
        component: MenuPopover,
        event: event
      })
      return popover.present()
    }
  }
})
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin-left: 20px;
  margin-right: 20px;
}

#container a {
  text-decoration: none;
}

.scanner {
  width: 90%;
  max-width: 400px;
  margin: auto;
}

.qr-image {
  width: 95%;
  max-width: 400px;
  margin: auto;
}
</style>
