<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, toastController } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data() {
    return {
      registration: null,
      refreshing: false
    }
  },
  created() {
    this.registration = null
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    refreshApp() {
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    async updateAvailable(event) {
      this.registration = event.detail
      const toast = await toastController.create({
        header: 'Update available',
        message: 'Please tap "Update" to reload the App.',
        position: 'bottom',
        buttons: [
          {
            side: 'end',
            text: 'Update',
            handler: this.refreshApp
          }
        ]
      })
      toast.present()
    }
  }
})
</script>
