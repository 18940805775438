<template>
  <ion-content>
    <ion-list>
      <ion-item lines="none" detail="false" button @click="resetQrData">{{
        $t('home.reset')
      }}</ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonContent, IonList, IonItem } from '@ionic/vue'
import useQrStorage from '@/composables/storage-service'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuPopover',
  components: {
    IonContent,
    IonList,
    IonItem
  },
  setup() {
    const qrStorage = useQrStorage()
    return {
      qrStorage
    }
  },
  methods: {
    resetQrData() {
      this.qrStorage.clear()
    }
  }
})
</script>

<style scoped></style>
