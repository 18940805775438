import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './registerServiceWorker'

import { IonicVue } from '@ionic/vue';

import { ellipsisVertical, cameraReverse } from 'ionicons/icons'
import { addIcons } from 'ionicons';

addIcons({
  'ellipsis-vertical': ellipsisVertical,
  'camera-reverse': cameraReverse
})

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    home: {
      title: "QR-Card",
      header: "QR-Card App",
      msg1: "You can use this app as a replacement for your QR-Card. To start using the app, please tap the button to start the configuration process.",
      msg2: "You will be asked to allow this app to use your camera to scan the QR-Code on your QR-Card. After scanning the code, everytime you open the app, it is displayed to enable you to clock-in or clock-out.",
      reset: "Reset",
      configure: "Configure",
    },
    configure: {
      title: "Scan QR-Card",
    },
    errors: {
      qrCodeScan: "Error scanning the qr-code."
    }
  },
  de: {
    home: {
      title: "QR-Karte",
      header: "QR-Karten-App",
      msg1: "Sie können diese Webseite/App als Ersatz für Ihre QR-Karte verwenden. Um die Webseite/App zu nutzen, tippen Sie auf \"Konfigurieren\", um den Konfigurationsprozess zu starten.",
      msg2: "Sie werden aufgefordert, dieser Webseite/App zu erlauben, Ihre Kamera zum Scannen des QR-Codes auf Ihrer QR-Karte zu verwenden. Nach dem Scannen des Codes wird dieser jedes Mal, wenn Sie die Webseite/App öffnen, angezeigt, damit Sie sich ein- oder ausstempeln können.",
      reset: "Zurücksetzen",
      configure: "Konfigurieren",
    },
    configure: {
      title: "QR-Karte scannen",
    },
    errors: {
      qrCodeScan: "Fehler beim Scannen des QR-Codes."
    }
  }
}

const i18n = createI18n({
  locale: "de",
  fallbackLocale: 'en',
  messages
});

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(i18n);
  
router.isReady().then(() => {
  app.mount('#app');
});
